<div>
  <h5>{{ '1. Download app' | translate }}</h5>
  <p>
    {{
      'Download an authenticator app for your smartphone if you haven’t already done so (e.g. a few are listed below).'
        | translate
    }}
  </p>

  <ul>
    <li>
      <a target="_blank" href="https://itunes.apple.com/nl/app/lastpass-authenticator/id1079110004">{{
        'Download Authenticator app for iOS' | translate
      }}</a>
    </li>
    <li>
      <a target="_blank" href="https://play.google.com/store/apps/details?id=com.lastpass.authenticator"
        >{{ 'Download Authenticator app for Android' | translate }}
      </a>
    </li>
  </ul>
</div>

<div>
  <h5>{{ '2. Scan QR-code in app' | translate }}</h5>
  <p>{{ 'Scan the QR-code below or add the secret code underneath to your authenticator app.' | translate }}</p>

  <content-state
    [isEmpty]="!qr?.qr_url"
    [isLoading]="loading"
    icon="closev2"
    size="small"
    [title]="'Unable to load QR-code' | translate"
    [content]="'Try reloading the page' | translate"
  >
    <div class="flex justify-center">
      <img align="middle" [src]="qr?.qr_url" width="200" height="200" />
    </div>
    <div class="flex justify-center font-semibold" data-testid="qr-secret">
      <h5>{{ qr?.secret }}</h5>
    </div>
  </content-state>
</div>

<div>
  <h5>{{ '3. Verify code' | translate }}</h5>
  <p>
    {{
      'Enter the current code from your authenticator app to verify your device and enable Multifactor authentication.'
        | translate
    }}
  </p>
  <modal-field [label]="'Password' | translate" [control]="form.get('password')">
    <input
      class="form-modal__input-short"
      type="password"
      [placeholder]="'Password' | translate"
      [formControl]="form.get('password')"
    />
  </modal-field>
  <modal-field [label]="'Response code' | translate" [control]="form.get('verification_code')">
    <input
      class="form-modal__input-short"
      type="text"
      id="token-name"
      name="verification_code"
      [placeholder]="'Response code' | translate"
      [formControl]="form.get('verification_code')"
    />
  </modal-field>
</div>
